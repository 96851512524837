
.pdp-sticky-cta-shipping {
    .shipping-item {
        display: flex;
        gap: 8px;
        margin-top: 12px;



        &:first-child {
            margin-top: 0;
        }

        .shipping-item-icon {
            width: 16px;
            height: 16px;

            .img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    &.is-sticky .shipping-item {
        margin-top: 8px;
    }
}

.remark {
    color: $color-gray-2;
}
